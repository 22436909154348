import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				О компании и услугах | Iron Haven Gym
			</title>
			<meta name={"description"} content={"Повысьте свой уровень фитнеса"} />
			<meta property={"og:title"} content={"О компании и услугах | Iron Haven Gym"} />
			<meta property={"og:description"} content={"Повысьте свой уровень фитнеса"} />
			<meta property={"og:image"} content={"https://vibratpanacea.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vibratpanacea.com/img/9043206324.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vibratpanacea.com/img/9043206324.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vibratpanacea.com/img/9043206324.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://vibratpanacea.com/img/4.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-lightD1"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					as="h1"
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-text-align="left"
				>
					Наши предложения
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					В Iron Haven Gym мы стремимся предоставить широкий спектр услуг, направленных на достижение ваших фитнес-целей и улучшение общего самочувствия. Наши комплексные предложения гарантируют, что у вас есть все необходимое для достижения успеха, независимо от того, хотите ли вы укрепить силу, повысить выносливость или обрести баланс и гибкость.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Связаться с нами
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Наша команда сертифицированных тренеров и дружелюбный персонал - основа Iron Haven Gym. Каждый тренер обладает богатым опытом и знаниями, предлагая индивидуальные рекомендации, чтобы помочь вам достичь поставленных целей. Независимо от того, нужны ли вам индивидуальные тренировки или групповые занятия, наши специалисты готовы поддержать вас на каждом шагу.
				</Text>
			</Box>
		</Section>
		<Section padding="64px 0" sm-padding="40px 0" font="--base" color="--dark">
			<Override slot="SectionContent" align-items="center" />
			<Box
				margin="40px -16px -16px -16px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="24px"
				md-grid-gap="16px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="32px"
				width="100%"
			>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://vibratpanacea.com/img/5.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Персональные тренировки
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Индивидуальные тренировки: Индивидуальные планы тренировок, соответствующие вашим целям и текущему уровню физической подготовки.
						<br />
						<br />
Индивидуальные занятия: Индивидуальный подход со стороны опытных тренеров, чтобы вы получали максимальную отдачу от каждой тренировки.
						<br />
						<br />
Отслеживание прогресса: Регулярные оценки для отслеживания вашего прогресса и корректировки программы по мере необходимости.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://vibratpanacea.com/img/6.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Групповые занятия фитнесом
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Разнообразие занятий: От высокоинтенсивных интервальных тренировок до успокаивающей йоги - здесь найдется занятие для каждого.
						<br />
						<br />
Сертифицированные инструкторы: Опытные инструкторы ведут каждое занятие, обеспечивая правильную технику и безопасность.
						<br />
						<br />
Участие в жизни сообщества: Наслаждайтесь мотивирующей и поддерживающей групповой средой, которая помогает вам быть подотчетными.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://vibratpanacea.com/img/7.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Рекомендации по питанию
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Планирование диеты: Индивидуальные планы питания, которые дополнят ваш фитнес-режим и помогут достичь поставленных целей.
						<br />
						<br />
Семинары по питанию: Интерактивные занятия, на которых вы узнаете о здоровом питании и выборе образа жизни.
						<br />
						<br />
Постоянная поддержка: Постоянная поддержка наших экспертов по питанию, чтобы вы не сбились с пути.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://vibratpanacea.com/img/8.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
					Передовое оборудование для фитнеса
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
					Кардиотренажеры: Современные беговые дорожки, орбитреки и стационарные велосипеды для эффективных сердечно-сосудистых тренировок.
						<br />
						<br />
Оборудование для силовых тренировок: Полный набор свободных весов, тренажеров и лент для наращивания мышц.
						<br />
						<br />
Зона функционального тренинга: пространство, предназначенное для функциональных упражнений, которые улучшают общую силу и координацию тела.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});